<template>
  <v-container fluid>
    <v-card elevation="0" class="pb-5">
      <v-card-title>언론사 분석</v-card-title>
      <v-row no-gutters class="mx-5">
        <v-col cols="12" lg="3" sm="12">
          <v-autocomplete
            v-model="media_id"
            :items="media_items"
            label="언론사"
            item-text="name"
            item-value="id"
            no-data-text="존재하지 않는 언론사입니다."
            hide-details
            @change="getData()"
          >
          </v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" lg="2" sm="12" class="mr-3">
          <v-select
            v-model="gender_id"
            :items="gender_items"
            label="성별"
            item-text="name"
            item-value="id"
            hide-details
            @change="getData()"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="2" sm="12" class="mr-3">
          <v-select
            v-model="age_id"
            :items="age_items"
            label="연령"
            item-text="name"
            item-value="id"
            hide-details
            @change="getData()"
          ></v-select
        ></v-col>
        <v-btn @click="refresh()" class="mx-auto mt-3">초기화</v-btn>
      </v-row>
    </v-card>
    <v-row class="mt-5 mb-16">
      <v-progress-circular
        v-if="loading"
        :indeterminate="loading"
        class="progress_circular"
      ></v-progress-circular>
      <v-col>
        <v-simple-table>
          <template>
            <thead class="thead">
              <tr>
                <th class="text-center">언론사</th>
                <th class="text-center">누적 응답자 수</th>
                <th class="text-center">성연령 응답자 수</th>
                <th class="text-center">CVR</th>
                <th class="text-center">남성</th>
                <th class="text-center">여성</th>
                <th class="text-center">10대</th>
                <th class="text-center">20대</th>
                <th class="text-center">30대</th>
                <th class="text-center">40대</th>
                <th class="text-center">50대</th>
                <th class="text-center">60대</th>
              </tr>
            </thead>
            <tbody class="tbody">
              <tr v-for="(item, i) in tableItems" :key="i">
                <td>{{ item.media_name }}</td>
                <td>{{ item.total_count.toLocaleString() }}</td>
                <td>{{ item.total_gender_ages_count.toLocaleString() }}</td>
                <td>{{ item.cvr }}</td>
                <td
                  :class="{
                    max: item.minmax.gender.max === item.gender_percent.male,
                    min: item.minmax.gender.min === item.gender_percent.male,
                  }"
                >
                  {{ item.gender_percent.male + "%" }}
                </td>
                <td
                  :class="{
                    max: item.minmax.gender.max === item.gender_percent.female,
                    min: item.minmax.gender.min === item.gender_percent.female,
                  }"
                >
                  {{ item.gender_percent.female + "%" }}
                </td>
                <td
                  :class="{
                    max: item.minmax.age.max === item.age_percent.age_10,
                    min: item.minmax.age.min === item.age_percent.age_10,
                  }"
                >
                  {{ item.age_percent.age_10 + "%" }}
                </td>
                <td
                  :class="{
                    max: item.minmax.age.max === item.age_percent.age_20,
                    min: item.minmax.age.min === item.age_percent.age_20,
                  }"
                >
                  {{ item.age_percent.age_20 + "%" }}
                </td>
                <td
                  :class="{
                    max: item.minmax.age.max === item.age_percent.age_30,
                    min: item.minmax.age.min === item.age_percent.age_30,
                  }"
                >
                  {{ item.age_percent.age_30 + "%" }}
                </td>
                <td
                  :class="{
                    max: item.minmax.age.max === item.age_percent.age_40,
                    min: item.minmax.age.min === item.age_percent.age_40,
                  }"
                >
                  {{ item.age_percent.age_40 + "%" }}
                </td>
                <td
                  :class="{
                    max: item.minmax.age.max === item.age_percent.age_50,
                    min: item.minmax.age.min === item.age_percent.age_50,
                  }"
                >
                  {{ item.age_percent.age_50 + "%" }}
                </td>
                <td
                  :class="{
                    max: item.minmax.age.max === item.age_percent.age_60,
                    min: item.minmax.age.min === item.age_percent.age_60,
                  }"
                >
                  {{ item.age_percent.age_60 + "%" }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MediaAnalysis",
  data() {
    return {
      media_id: "",
      media_items: [
        {
          id: "",
          name: "전체",
        },
      ],

      gender_id: "",
      gender_items: [
        {
          id: "",
          name: "전체",
        },
        {
          id: "male",
          name: "남성",
        },
        {
          id: "female",
          name: "여성",
        },
      ],

      age_id: "",
      age_items: [
        {
          id: "",
          name: "전체",
        },
        {
          id: "10",
          name: "10대",
        },
        {
          id: "20",
          name: "20대",
        },
        {
          id: "30",
          name: "30대",
        },
        {
          id: "40",
          name: "40대",
        },
        {
          id: "50",
          name: "50대",
        },
        {
          id: "60",
          name: "60대",
        },
      ],

      loading: false,
      tableItems: [],
    };
  },
  mounted() {
    this.getMedia();
    this.getData();
  },
  methods: {
    getMedia() {
      this.axios.get("api/v1/manage/list/medias").then((res) => {
        if (res.data.list.data.length > 0) {
          res.data.list.data.map((item) => {
            this.media_items.push({
              id: item.id,
              name: item.name,
            });
          });
        }
      });
    },

    refresh() {
      this.media_id = "";
      this.gender_id = "";
      this.age_id = "";
      this.getData();
    },

    async getData() {
      this.loading = true;

      let params = {};
      if (this.media_id !== "") {
        params["media_ids[]"] = this.media_id;
      }
      if (this.gender_id !== "") {
        params["gender"] = this.gender_id;
      }
      if (this.age_id !== "") {
        params["age"] = this.age_id;
      }
      let tableItems = [];
      let ages = [10, 20, 30, 40, 50, 60];
      await this.axios
        .get(`api/v1/stats/inspect/media`, { params })
        .then((res) => {
          res.data.stats.map((el) => {
            tableItems.push({
              media_id: el.media_info.media_id,
              media_name: el.media_info.media_name,
              total_count: el.details.total,
              total_gender_ages_count: el.details.total_gender_ages,
              gender_percent: {
                male: el.details.percentages.male.toFixed(2),
                female: el.details.percentages.female.toFixed(2),
              },
              age_percent: {
                age_10:
                  el.details.percentages.ages[ages[0].toString()].toFixed(2),
                age_20:
                  el.details.percentages.ages[ages[1].toString()].toFixed(2),
                age_30:
                  el.details.percentages.ages[ages[2].toString()].toFixed(2),
                age_40:
                  el.details.percentages.ages[ages[3].toString()].toFixed(2),
                age_50:
                  el.details.percentages.ages[ages[4].toString()].toFixed(2),
                age_60:
                  el.details.percentages.ages[ages[5].toString()].toFixed(2),
              },
              cvr: el.cvrStats,
              minmax: {
                age: {
                  max: el.details.minmax.age.max.toFixed(2),
                  min: el.details.minmax.age.min.toFixed(2),
                },
                gender: {
                  max: el.details.minmax.gender.max.toFixed(2),
                  min: el.details.minmax.gender.min.toFixed(2),
                },
              },
            });
          });
          this.tableItems = tableItems;
        });
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.min {
  color: red;
  font-weight: 600;
}
.max {
  color: blue;
  font-weight: 600;
}
.thead tr th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.thead tr:first-child th:last-of-type {
  border-right: 0;
}
.tbody tr td:not(:first-of-type) {
  text-align: right;
}
.tbody tr td:nth-of-type(-n + 4),
.tbody tr td:nth-of-type(6) {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.progress_circular {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
